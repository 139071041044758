@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  background: linear-gradient(to right, #11998e, #38ef7d);
  /* Set background gradient */
  margin: 0;
  padding: 0;
  font-family: "DM sans";
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 40px;
  height: auto;
  background-color: #ffffff;
  border-radius: 20px;
}

@media (max-width: 640px) {
  form {
    width: 70%;
    padding: 20px;
  }
}

.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  margin-bottom: 5px;
  margin-top: 5px;
}

input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

p {
  margin: 5px 0;
  color: red;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}
